(function() {

dmx.Formatters('string', {

    // startWith(search:String):Boolean
    startsWith: function(string, search) {
        return string.indexOf(search) === 0;
    },

    // endsWith(search:String):Boolean
    endsWith: function(string, search) {
        var pos = string.length - search.length;
        var index = string.lastIndexOf(search);
        return index !== -1 && index === pos;
    },

    // contains(search:String):Boolean
    contains: function(string, search) {
        return string.indexOf(search) !== -1;
    },

    search: function(string, search, matchAll) {
        return String(search).split(/\s+/)[matchAll ? 'every' : 'some'](function(word) {
            return string.indexOf(word) !== -1;
        });
    },

    // fuzzySearch(search:String):Boolean
    fuzzySearch: function(string, search) {
        var stringLen = string.length;
        var searchLen = search.length;
        if (searchLen > stringLen) {
            return false;
        }
        if (searchLen === stringLen) {
            return string === search;
        }
        outer: for (var i = 0, j = 0; i < searchLen; i++) {
            var char = search.charCodeAt(i);
            while (j < stringLen) {
                if (string.charCodeAt(j++) === char) {
                    continue outer;
                }
            }
            return false;
        }
        return true;
    },

    // test(regexp:RegExp):Boolean
    test: function(string, regexp) {
        return regexp.test(string);
    },

    // lowercase():String
    lowercase: function(string) {
        return string.toLowerCase();
    },

    // uppercase():String
    uppercase: function(string) {
        return string.toUpperCase();
    },

    // slugify():String
    slugify: function(string) {
        return string.toLowerCase().replace(/[^\w\s]/g, '').replace(/[_\s]+/g, '-').replace(/-+/g, '-').replace(/^-/, '');
    },

    // camelize():String
    camelize: function(string) {
        return string.trim().replace(/(\-|_|\s)+(.)?/g, function(a, b, c) {
            return (c ? c.toUpperCase() : '');
        });
    },

    // capitalize():String
    capitalize: function(string) {
        return string.substr(0, 1).toUpperCase() + string.substr(1).toLowerCase();
    },

    // dasherize():String
    dasherize: function(string) {
        return string.replace(/[_\s]+/g, '-').replace(/([A-Z])/g, '-$1').replace(/-+/g, '-').toLowerCase();
    },

    // humanize():String
    humanize: function(string) {
        var str = string.replace(/([a-z\d])([A-Z+])/g, '$1_$2').replace(/[-\s]+/g, '_').replace(/_+/g, ' ').trim();
        return str.substr(0, 1).toUpperCase() + str.substr(1).toLowerCase();
    },

    // underscore():String
    underscore: function(string) {
        return string.trim().replace(/([a-z\d])([A-Z]+)/g, '$1_$1').replace(/[-\s]+/g, '_').toLowerCase();
    },

    // titlecase():String
    titlecase: function(string) {
        return string.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
            return a.toUpperCase();
        });
    },

    // camelcase():String
    camelcase: function(string) {
        return string.toLowerCase().replace(/\s+(\S)/g, function(a, b) {
            return b.toUpperCase();
        });
    },

    // substr(start:Number, [length:Number]):String
    substr: function(string, start, length) {
        return string.substr(start, length);
    },

    // substring(start:Number, [end:Number]):String
    substring: function(string, start, end) {
        return string.substring(start, end);
    },

    // replace(search:String|RegExp, replace:String):String
    replace: function(string, search, replace) {
        if (typeof search == 'string') {
            search = new RegExp(dmx.escapeRegExp(search), 'g');
        }
        return string.replace(search, replace);
    },

    // trim():String
    trim: function(string) {
        return string.trim();
    },

    // trimLeft():String
    trimLeft: function(string) {
        return string.replace(/^[\x09\x0A\x0B\x0C\x0D\x20\xA0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u3000\u2028\u2029\uFEFF]*/, '');
    },

    // trimRight():String
    trimRight: function(string) {
        return string.replace(/[\x09\x0A\x0B\x0C\x0D\x20\xA0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u3000\u2028\u2029\uFEFF]*$/, '');
    },

    // concat(string2:String):String
    concat: function(string, string2) {
        return string.concat(string2);
    },

    // trunc(charsAllowed:Number, [useWordBoundary:Boolean], [append:String]):String
    trunc: function(string, charsAllowed, useWordBoundary, append) {
        useWordBoundary = !!useWordBoundary;
        append = append || '…';

        if (string.length > charsAllowed) {
            string = string.substr(0, charsAllowed);

            if (useWordBoundary && string.indexOf(' ') !== -1) {
                string = string.substr(0, string.lastIndexOf(' '));
            }

            string += append;
        }

        return string;
    },

    // stripTags():String
    stripTags: function(string) {
        return string.replace(/<[^>]+>/g, '');
    },

    // padStart(length:Number, [filler:String]):String
    padStart: function(string, length, filler) {
        filler = filler || ' ';

        if (length <= string.length) {
            return string;
        }

        var rem = length - string.length;
        while (filler.length < rem) {
            filler += filler;
        }

        return filler.substr(0, rem) + string;
    },

    // padEnd(length:Number, [filler:String]):String
    padEnd: function(string, length, filler) {
        filler = filler || ' ';

        if (length <= string.length) {
            return string;
        }

        var rem = length - string.length;
        while (filler.length < rem) {
            filler += filler;
        }

        return string + filler.substr(0, rem);
    },

    // repeat(count:Number):String
    repeat: function(string, count) {
        var out = '';
        for (var i = 0; i < count; i++) {
            out += string;
        }
        return out;
    },

    // wordCount():Number
    wordCount: function(string) {
        return string.trim().split(/\s+/).length;
    },

    // split(separator:String|RegExp):Array
    split: function(string, separator) {
        return string.split(separator);
    },

    // default()
    default: function(string) {
        return string;
    },

    // toNumber():Number
    toNumber: function(string) {
        return Number(string);
    },

    // Date formatters (requires a date string)

    toDate: function(string) {
        var date = parseDate(string);

        if (date.toString() == 'Invalid Date') {
            return null;
        }

        return date.toISOString();
    },

    // addYears(years:Number):String
    addYears: function(string, years) {
        var date = parseDate(string);
        date.setFullYear(date.getFullYear() + years);
        return date.toString() == 'Invalid' ? null : date.toISOString();
    },

    // addMonths(months:Number):String
    addMonths: function(string, months) {
        var date = parseDate(string);
        date.setMonth(date.getMonth() + months);
        return date.toString() == 'Invalid' ? null : date.toISOString();
    },

    // addWeeks(weeks:Number):String
    addWeeks: function(string, weeks) {
        var date = parseDate(string);
        date.setDate(date.getDate() + (weeks * 7));
        return date.toString() == 'Invalid' ? null : date.toISOString();
    },

    // addDays(days:Number):String
    addDays: function(string, days) {
        var date = parseDate(string);
        date.setDate(date.getDate() + days);
        return date.toString() == 'Invalid' ? null : date.toISOString();
    },

    // addHours(hours:Number):String
    addHours: function(string, hours) {
        var date = parseDate(string);
        date.setHours(date.getHours() + hours);
        return date.toString() == 'Invalid' ? null : date.toISOString();
    },

    // addMinutes(minutes:Number):String
    addMinutes: function(string, minutes) {
        var date = parseDate(string);
        date.setMinutes(date.getMinutes() + minutes);
        return date.toString() == 'Invalid' ? null : date.toISOString();
    },

    // addSeconds(seconds:Number):String
    addSeconds: function(string, seconds) {
        var date = parseDate(string);
        date.setSeconds(date.getSeconds() + seconds);
        return date.toString() == 'Invalid' ? null : date.toISOString();
    },

    // yearsUntil(date:String):Number
    yearsUntil: function(string, date) {
        var date1 = parseDate(string);
        var date2 = parseDate(date);
        return date2.getFullYear() - date1.getFullYear();
    },

    // monthsUntil(date:String):Number
    monthsUntil: function(string, date) {
        var date1 = parseDate(string);
        var date2 = parseDate(date);
        return (date2.getFullYear() * 12 + date2.getMonth()) - (date1.getFullYear() * 12 + date1.getMonth());
    },

    // weeksUntil(date:String):Number
    weeksUntil: function(string, date) {
        var date1 = parseDate(string);
        var date2 = parseDate(date);
        return Math.floor((date2 - date1) / 604800000); // 7 * 24 * 60 * 60 * 1000
    },

    // daysUntil(date:String):Number
    daysUntil: function(string, date) {
        var date1 = resetTime(parseDate(string));
        var date2 = resetTime(parseDate(date));
        return Math.round((date2 - date1) / 86400000); // 24 * 60 * 60 * 1000
    },

    // hoursUntil(date:String):Number
    hoursUntil: function(string, date) {
        var date1 = parseDate(string);
        var date2 = parseDate(date);
        return Math.floor((date2 - date1) / 3600000); // 60 * 60 * 1000
    },

    // minutesUntil(date:String):Number
    minutesUntil: function(string, date) {
        var date1 = parseDate(string);
        var date2 = parseDate(date);
        return Math.floor((date2 - date1) / 60000); // 60 * 1000
    },

    // secondsUntil(date:String):Number
    secondsUntil: function(string, date) {
        var date1 = parseDate(string);
        var date2 = parseDate(date);
        return Math.floor((date2 - date1) / 1000);
    },

    // timeUntil(date:String, [includeHours:Boolean]):String
    timeUntil: function(string, date, includeHours) {
        var pad = function(n) { return (n < 10 ? '0' : '') + n; };
        var date1 = parseDate(string);
        var date2 = parseDate(date);
        var seconds = Math.floor((date2 - date1) / 1000);
        var minutes = Math.floor(seconds / 60);
        var hours = Math.floor(minutes / 60);
        seconds -= minutes * 60;
        if (includeHours) {
            minutes -= hours * 60;
            return pad(hours) + ':' + pad(minutes) + ':' + pad(seconds);
        }
        return pad(minutes) + ':' + pad(seconds);
    },

    toISODate: function(string) {
        var date = parseDate(string);
        if (date.toString() == 'Invalid Date') {
            return null;
        }
        var pad2 = function(n) { return ('00' + n).substr(-2); };
        var pad4 = function(n) { return ('0000' + n).substr(-4); };
        return pad4(date.getFullYear()) + '-' + pad2(date.getMonth()) + '-' + pad2(date.getDate());
    },

    toISOTime: function(string) {
        var date = parseDate(string);
        if (date.toString() == 'Invalid Date') {
            return null;
        }
        var pad2 = function(n) { return ('00' + n).substr(-2); };
        return pad2(date.getHours()) + ':' + pad2(date.getMinutes()) + ':' + pad2(date.getSeconds());
    },

    // formatDate(format:String):String
    formatDate: function(string, format) {
        var date = parseDate(string);
        if (date.toString() == 'Invalid Date') {
            return null;
        }
        var pad2 = function(n) { return ('00' + n).substr(-2); };
        var pad4 = function(n) { return ('0000' + n).substr(-4); };
        var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        var monthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        var daysShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        var year = date.getFullYear();
    	var month = date.getMonth();
    	var day = date.getDate();
    	var weekday = date.getDay();
    	var hours = date.getHours();
    	var minutes = date.getMinutes();
    	var seconds = date.getSeconds();
        return format.replace(/[yMdHhmsaA]+/g, function(part) {
            switch (part) {
                case 'yyyy': return pad4(year);
                case 'yy': return pad2(year);
                case 'y': return year;
                case 'MMMM': return months[month];
                case 'MMM': return monthsShort[month];
                case 'MM': return pad2(month + 1);
                case 'M': return month + 1;
                case 'dddd': return days[weekday];
                case 'ddd': return daysShort[weekday];
                case 'dd': return pad2(day);
                case 'd': return day;
                case 'HH': return pad2(hours);
                case 'H': return hours;
                case 'hh': return pad2((hours % 12) || 12);
                case 'h': return (hours % 12) || 12;
                case 'mm': return pad2(minutes);
                case 'm': return minutes;
                case 'ss': return pad2(seconds);
                case 's': return seconds;
                case 'a': return hours < 12 ? 'am' : 'pm';
                case 'A': return hours < 12 ? 'AM' : 'PM';
            }

            return part;
        });
    },

    // toTimestamp():Number
    toTimestamp: function(string) {
        var date = parseDate(string);
        if (date.toString() == 'Invalid Date') {
            return null;
        }
        return Math.floor(date.getTime() / 1000);
    }

});

function parseDate(string) {
    var d, struct, offset = 0, n = [1,4,5,6,7,10,11];

    if (string.toLowerCase() == 'now') {
        return new Date();
    }

    if ((struct = /^(\d{4}|[+\-]\d{6})(?:-(\d{2})(?:-(\d{2}))?)?(?:[T ](\d{2}):(\d{2})(?::(\d{2})(?:\.(\d{3}))?)?(?:(Z)|([+\-])(\d{2})(?::(\d{2}))?)?)?$/.exec(string))) {
        for (var i = 0, k; (k = n[i]); ++i) {
            struct[k] = +struct[k] || 0;
        }

        struct[2] = (+struct[2] || 1) - 1;
        struct[3] = +struct[3] || 1;

        if (struct[8] === undefined) {
            return new Date(struct[1], struct[2], struct[3], struct[4], struct[5], struct[6], struct[7]);
        } else {
            if (struct[8] !== 'Z' && struct[9] !== undefined) {
                offset = struct[10] * 60 + struct[11];
                if (struct[9] === '+') offset = 0 - offset;
            }

            return new Date(Date.UTC(struct[1], struct[2], struct[3], struct[4], struct[5] + offset, struct[6], struct[7]));
        }
    } else if ((struct = /^(\d{2}):(\d{2})(?::(\d{2}))?$/.exec(string))) {
        var d = new Date();
        d.setHours(+struct[1] || 0);
        d.setMinutes(+struct[2] || 0);
        d.setSeconds(+struct[3] || 0);
        return d;
    }

    return new Date(string);
}

function resetTime(date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
}

})();
